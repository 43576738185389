<template>
  <div>
    <a-button class="add-com-btn" type="primary" @click="edit=false;addStard={};visible=true">添加套餐</a-button>
    <a-table :columns="roleColums" :data-source="stardList" :pagination="false">
      <span slot="action" slot-scope="text, record">
        <a @click.prevent="editItem(record)" >编辑</a>
        <a-divider type="vertical" />
        <span class="delete-btn" @click="remove(record)">删除</span>
      </span>
    </a-table>
     <!--  添加角色  -->
    <a-modal v-model="visible" :title="edit?'编辑套餐':'添加套餐'" @cancel="visible=false" @ok="addMethod" okText="确定" cancelText="取消">
      <p class="flex">
        <label class="type-name">套餐名</label> 
        <a-input class="flex1" v-model="addStard.trainType" placeholder="套餐名" />
      </p>
      <p class="flex">
        <label class="type-name">数量</label> 
        <a-input class="flex1" v-model="addStard.trainNum" placeholder="训练数量" />
      </p>
      <p class="flex">
        <label class="type-name">总价</label> 
        <a-input class="flex1" v-model="addStard.trainPrice" placeholder="价钱" />
      </p>
    </a-modal> 
  </div>
</template>
<script>
const roleColums = [
  {
    title: '套餐名',
    dataIndex: 'trainType',
    key: 'trainType',
  },
  {
    title: '数量',
    dataIndex: 'trainNum',
    key: 'trainNum',
  },
  {
    title: '总价格',
    dataIndex: 'trainPrice',
    key: 'trainPrice',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  name:'standerd',
  data() {
    return {
      roleColums,
      stardList:[],
      visible:false,
      edit:false,
      addStard:{},
    }
  },
  mounted(){
    this.getStardList()
  },
  methods: {
    remove(item){
      this.$confirm({
        title:'是否确认删除？',
        content:`是否确认删除套餐？`,
        onOk:()=>{
          this.$post(this.$url.删除套餐, {
            id:item.id,
            examSpotId:this.$store.state.curSpotId
          }).then(data => {
            this.$tips('删除成功','该套餐删除成功！','success')
            this.getStardList()
          }).catch(() => {
            this.$tips('删除失败','请重新操作！','error')
          });
        }
      })
    },
    editItem(item){
      this.edit = true;
      this.addStard = Object.assign({},item);
      this.visible = true
    },
    getStardList(){
      this.$post(this.$url.强训套餐列表,{
        examSpotId:this.$store.state.curSpotId
      }).then(data => {
        this.stardList = data.data.list
      }).catch(() => {
          
      });
    },
    editMethod(item){
      this.$post(this.$url.编辑套餐,this.addStard).then(data => {
        this.visible = false;
        this.$tips('操作成功','角色编辑成功！','success')
        this.getStardList()
      }).catch(() => {
          
      });
    },
    addMethod(){
      this.addStard.examSpotId = this.$store.state.curSpotId
      if(this.edit){
        this.editMethod();
        return
      }
      this.$post(this.$url.添加套餐, this.addStard).then(data => {
        this.visible = false;
        this.$tips('添加成功','角色添加成功！','success')
        this.getStardList()
      }).catch((err) => {
        this.$tips('添加失败','请重新添加角色！','error')
      });
    },
  },
}
</script>
<style scoped>
.sed-nav{
  display:block;
  padding-left:0px;
  margin:8px 0;
}
.shousuo{
  margin-right:5px;
}
.second-nav{
  padding-left:30px;
  display: block;
}
.thr-nav{
  padding-left:0px;
  display:block;
  margin:8px 0;
}
</style>